<template>
  <div class="modal-alert flex flex-col items-center py-100 px-64 text-grey-900">
    <h1 class="mt-24 text-32 leading-150 tracking-038">
      {{ $t(translationKeys.title) }}
    </h1>

    <p class="mt-16 text-18 leading-156 text-center">
      {{ $t(translationKeys.text) }}
    </p>

    <app-button class="continue mt-56 bg-green-400 font-bbva" @click="$modal.close()">{{
      $t('SHARED.CLOSE')
    }}</app-button>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'

export default {
  name: 'Alert',

  components: {
    AppButton,
  },

  props: {
    translationKeys: {
      required: true,
      type: Object,
    },
  },
}
</script>
